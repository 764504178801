.fridaySummary {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    .button {
        border: 2px solid white;
        color: white;
        padding: 1rem 2rem;
        font-weight: bold;
        background: transparent;
        cursor: pointer;
        font-size: 3rem;
        letter-spacing: 0.03rem;
        margin-bottom: 4rem;
    }
    .exitButton {
        border: 0;
        background: transparent;
        position: fixed;
        right: 3rem;
        top: 3rem;
        color: white;
        font-size: 2rem;
        cursor: pointer;
        font-weight: bold;
    }
    .marioWorld {
        width: 100%;
        height: 100%;
        background-image: url('./assets/Mario-world.png');
        background-repeat: no-repeat;
        background-position: bottom center;
        background-color: #5C94FC;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 8rem;
        .textContainer {
            position: absolute;
            margin-bottom: 220px;
            margin-left: -5rem;
            text-align: center;
            h1 {
                font-weight: 900;
                color: white;
                letter-spacing: 5px;
                text-shadow: 2px 2px 1px rgba(0,0,0,0.3);
                font-size: 7rem;
                margin-top: 0;
                margin-bottom: 1rem;
            }
            p {
                margin-bottom: 0;
                margin-top: 0;
                color: white;
                text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
            }
        }
        .mario {
            width: 64px;
            height: 66px;
            bottom: -22.3rem;
            left: -4.5rem;
            position: relative;
            background-image: url('./assets/Mario-sprite.png');
            transform: translate(0px, 0px);
            @keyframes mario-jump {
                0% {
                    background-image: url('./assets/Mario-sprite.png');
                    transform: translate(0px, 0px);
                }
                50% {
                    background-image: url('./assets/Mario-sprite-jump.png');
                    transform: translate(0px, -70px);
                } 100% {
                    background-image: url('./assets/Mario-sprite.png');
                    transform: translate(0px, 0px);
                }
            }
            &.jumping {
                animation-name: mario-jump;
                animation-duration: 0.7s;
                animation-timing-function: ease-in-out;
                animation-direction: forwards;
            }
        }
        .flagMario {
            width: 64px;
            height: 66px;
            background-image: url('./assets/Mario-sprite.png');
            transition: 0.6s;

            @keyframes mario-run {
                0% {
                    transform: translateX(-200px);
                }
                100% {
                    transform: translateX(90px);
                }
            }

            &.run {
                animation: mario-run 1s forwards;
                animation-timing-function: ease-in;
            }

            &.jump {
                background-image: url('./assets/Mario-sprite-jump.png');
            }
        }
        .luigi {
            width: 64px;
            height: 66px;
            bottom: -0.3rem;
            left: 8rem;
            position: relative;
            background-image: url('./assets/Luigi-sprite.png');
            transform: translate(0px, 0px);
            @keyframes luigi-jump {
                0% {
                    background-image: url('./assets/Luigi-sprite.png');
                    transform: translate(0px, 0px);
                }
                50% {
                    background-image: url('./assets/Luigi-sprite-jump.png');
                    transform: translate(0px, -70px);
                } 100% {
                      background-image: url('./assets/Luigi-sprite.png');
                      transform: translate(0px, 0px);
                  }
            }
            &.jumping {
                animation-name: luigi-jump;
                animation-duration: 0.7s;
                animation-timing-function: ease-in-out;
                animation-direction: forwards;
            }
        }
        .coin {
            background-image: url('./assets/coin.png');
            height: 40px;
            width: 20px;
            background-position: 0 0;
            background-size: contain;
            margin-left: -2.7rem;
            transform: translate(0, 40px);
            &.show {
                @keyframes flip {
                    0% {
                        background-image: url('./assets/coin.png');
                        transform: translate(0, 40px);
                    }
                    33% {
                        background-image: url('./assets/coin-2.png');
                    }
                    50% {
                        transform: translate(0, -20px);
                    }
                    66% {
                        background-image: url('./assets/coin-3.png');
                    }
                    99% {
                        background-image: url('./assets/coin-4.png');
                        transform: translate(0, 40px);
                    }
                }
                animation-name: flip;
                animation-duration: 1s;
                animation-delay: 0.3s;
            }
        }
        .luigiCoin {
            background-image: url('./assets/coin.png');
            height: 40px;
            width: 20px;
            background-position: 0 0;
            background-size: contain;
            margin-left: 10.8rem;
            transform: translate(0, 40px);
            &.show {
                @keyframes flip {
                    0% {
                        background-image: url('./assets/coin.png');
                        transform: translate(0, 40px);
                    }
                    33% {
                        background-image: url('./assets/coin-2.png');
                    }
                    50% {
                        transform: translate(0, -20px);
                    }
                    66% {
                        background-image: url('./assets/coin-3.png');
                    }
                    99% {
                        background-image: url('./assets/coin-4.png');
                        transform: translate(0, 40px);
                    }
                }
                animation-name: flip;
                animation-duration: 1s;
                animation-delay: 0.3s;
            }
        }
        .moneyBox {
            background-image: url('./assets/money-box.png');
            width: 47px;
            height: 47px;
            margin-bottom: -15.3rem;
            margin-left: -4.1rem;
            position: relative;
            z-index: 1;
        }
        .luigiMoneyBox {
            @extend .moneyBox;
            margin-bottom: 6.8rem;
            margin-left: 10.2rem;
            right: 1rem;
            z-index: 1;
        }
    }
}